<template>
  <q-page>
    <DeviatingVehicleWithCanceledTable />
  </q-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import DeviatingVehicleWithCanceledTable from '@/components/tools/deviating-vehicle-with-canceled/DeviatingVehicleWithCanceledTable.vue'

export default defineComponent({
  name: 'DeviatingVehicleWithCanceled',

  components: {
    DeviatingVehicleWithCanceledTable,
  },

  setup() {
    return {}
  },
})
</script>
