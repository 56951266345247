import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { DeviationVehicle } from '@/types/deviation-vehicle'

interface FetchParams {
  from: string
  to: string
}

let abortController: AbortController

export function getDeviationVehicles(
  params: FetchParams
): Promise<AxiosResponse<DeviationVehicle[]>> {
  if (abortController) {
    abortController.abort()
    abortController = new AbortController()
  } else {
    abortController = new AbortController()
  }
  return instance.get(`/deviation-vehicle`, {
    params,
    signal: abortController.signal,
  })
}
