import { render } from "./DeviationVehicleWithCanceledFilterModal.vue?vue&type=template&id=4f1d792d"
import script from "./DeviationVehicleWithCanceledFilterModal.vue?vue&type=script&lang=ts"
export * from "./DeviationVehicleWithCanceledFilterModal.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QMenu,QForm,QSlideTransition,QIcon,QSeparator,QBtn});
