<template>
  <q-menu style="width: 250px" v-model="showFilterMenu">
    <q-form ref="formRef" @submit.prevent="onFilter" autocomplete="off" greedy>
      <div class="row q-pa-md">
        <div class="row">
          <div class="col-12">
            <AppFieldDateRange label="Period" v-model="period" />
          </div>
        </div>
        <q-slide-transition>
          <div class="row items-center text-red-6" v-if="!validDate">
            <q-icon name="mdi-information-outline" class="q-mr-sm" size="xs" />
            Slut datum måste vara samma eller efter start datum!
          </div>
        </q-slide-transition>
      </div>
      <q-separator />
      <div class="row full-width q-pa-md">
        <q-btn type="submit" class="q-ml-auto" label="Filtrera" />
      </div>
    </q-form>
  </q-menu>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import AppFieldDateRange from '@/components/AppFieldDateRange.vue'
import { QForm } from 'quasar'
import { format, isAfter, isEqual, sub } from 'date-fns'
import { useDeviationVehicle } from '@/composable/useDeviationVehicle'
import { useDeviationCancel } from '@/composable/useDeviationCancel'

export default defineComponent({
  name: 'DeviationVehicleWithCanceledFilterModal',

  components: {
    AppFieldDateRange,
  },

  setup() {
    const period = ref({
      from: format(sub(new Date(), { days: 30 }), 'yyyy-MM-dd'),
      to: format(new Date(), 'yyyy-MM-dd'),
    })
    const {
      loading: loadingStateDeviationVehicle,
      fetchAll: fetchAllDeviationVehicle,
      period: vehiclePeriod,
    } = useDeviationVehicle()

    const {
      loading: loadingStateDeviationCancel,
      fetchAll: fetchAllDeviationCancel,
      period: cancelPeriod,
    } = useDeviationCancel()

    cancelPeriod.value = period.value
    vehiclePeriod.value = period.value

    const formRef = ref<QForm | null>(null)

    const showFilterMenu = ref(false)

    const init = async () => {
      await Promise.all([
        fetchAllDeviationCancel(period.value),
        fetchAllDeviationVehicle(period.value),
      ])
    }

    init()

    async function onFilter() {
      const isValid = await formRef.value?.validate()
      if (!isValid || !validDate.value) return
      cancelPeriod.value = period.value
      vehiclePeriod.value = period.value
      await Promise.all([
        fetchAllDeviationCancel(period.value),
        fetchAllDeviationVehicle(period.value),
      ])

      showFilterMenu.value = false
    }

    const validDate = computed(() => {
      return (
        isAfter(new Date(period.value.to), new Date(period.value.from)) ||
        isEqual(new Date(period.value.to), new Date(period.value.from))
      )
    })

    const loading = computed(() => {
      return (
        loadingStateDeviationCancel.value.getAll ||
        loadingStateDeviationVehicle.value.getAll
      )
    })

    return {
      showFilterMenu,
      loading,
      validDate,
      formRef,
      period,
      onFilter,
    }
  },
})
</script>
