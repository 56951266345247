import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { DeviationVehicle } from '@/types/deviation-vehicle'

export function updateDeviationVehicle(
  body: Partial<DeviationVehicle>,
  deviationVehicleUuid: string
): Promise<AxiosResponse<DeviationVehicle>> {
  return instance.put(`/deviation-vehicle/${deviationVehicleUuid}`, body)
}

export function updateDeviationVehicleDistance(
  deviationVehicleUuid: string,
  body: { distance: number }
): Promise<AxiosResponse<DeviationVehicle>> {
  return instance.put(
    `/deviation-vehicle/${deviationVehicleUuid}/distance`,
    body
  )
}
