<template>
  <q-input
    :square="square"
    outlined
    :label="label"
    :model-value="text"
    style="width: 220px"
  >
    <template v-slot:append>
      <q-icon name="mdi-calendar-range" class="cursor-pointer">
        <q-popup-proxy
          ref="qDateProxy"
          transition-show="scale"
          transition-hide="scale"
        >
          <q-date
            mask="YYYY-MM-DD"
            v-model="internalModel"
            range
            :subtitle="text"
          >
            <div class="row items-center justify-end">
              <q-btn v-close-popup label="Stäng" color="primary" flat />
            </div>
          </q-date>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from 'vue'

export default defineComponent({
  name: 'AppFieldDateRange',

  emits: ['update:modelValue'],

  props: {
    square: {
      type: Boolean,
      default: () => true,
    },
    mask: {
      type: String,
      default: () => '####-##-##',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    modelValue: null,
    label: String,
  },

  setup(props, { emit }) {
    const internalModel = ref(props.modelValue)

    watch(
      () => internalModel.value,
      (value) => {
        if (!value) return
        if (typeof value === 'string') {
          emit('update:modelValue', { from: value, to: value })
        } else {
          emit('update:modelValue', value)
        }
      },
      {
        immediate: true,
        deep: true,
      }
    )

    const text = computed(() => {
      if (!internalModel.value) return '-'
      if (typeof internalModel.value === 'string') {
        return internalModel.value
      } else {
        return `${internalModel.value.from} - ${internalModel.value.to}`
      }
    })

    return { internalModel, text }
  },
})
</script>
