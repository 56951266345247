
import { computed, defineComponent, ref } from 'vue'
import AppFieldDateRange from '@/components/AppFieldDateRange.vue'
import QForm from 'quasar/src/components/form/QForm.js';
import { format, isAfter, isEqual, sub } from 'date-fns'
import { useDeviationVehicle } from '@/composable/useDeviationVehicle'
import { useDeviationCancel } from '@/composable/useDeviationCancel'

export default defineComponent({
  name: 'DeviationVehicleWithCanceledFilterModal',

  components: {
    AppFieldDateRange,
  },

  setup() {
    const period = ref({
      from: format(sub(new Date(), { days: 30 }), 'yyyy-MM-dd'),
      to: format(new Date(), 'yyyy-MM-dd'),
    })
    const {
      loading: loadingStateDeviationVehicle,
      fetchAll: fetchAllDeviationVehicle,
      period: vehiclePeriod,
    } = useDeviationVehicle()

    const {
      loading: loadingStateDeviationCancel,
      fetchAll: fetchAllDeviationCancel,
      period: cancelPeriod,
    } = useDeviationCancel()

    cancelPeriod.value = period.value
    vehiclePeriod.value = period.value

    const formRef = ref<QForm | null>(null)

    const showFilterMenu = ref(false)

    const init = async () => {
      await Promise.all([
        fetchAllDeviationCancel(period.value),
        fetchAllDeviationVehicle(period.value),
      ])
    }

    init()

    async function onFilter() {
      const isValid = await formRef.value?.validate()
      if (!isValid || !validDate.value) return
      cancelPeriod.value = period.value
      vehiclePeriod.value = period.value
      await Promise.all([
        fetchAllDeviationCancel(period.value),
        fetchAllDeviationVehicle(period.value),
      ])

      showFilterMenu.value = false
    }

    const validDate = computed(() => {
      return (
        isAfter(new Date(period.value.to), new Date(period.value.from)) ||
        isEqual(new Date(period.value.to), new Date(period.value.from))
      )
    })

    const loading = computed(() => {
      return (
        loadingStateDeviationCancel.value.getAll ||
        loadingStateDeviationVehicle.value.getAll
      )
    })

    return {
      showFilterMenu,
      loading,
      validDate,
      formRef,
      period,
      onFilter,
    }
  },
})
