import { Ref, ref, ComputedRef, computed } from 'vue'
import { useLoading, Loading } from '@/composable/useLoading'
import { createDeviationVehicle } from '@/api/deviation-vehicle/createDeviationVehicle'
import { DeviationVehicle } from '@/types/deviation-vehicle'
import { updateDeviationVehicle } from '@/api/deviation-vehicle/updateDeviationVehicle'
import { useEvent } from '@/composable/useEvent'
import { deleteDeviationVehicle } from '@/api/deviation-vehicle/deleteDeviationVehicle'
import { getDeviationVehicles } from '@/api/deviation-vehicle/getDeviationVehicles'
import { addDays, format, sub } from 'date-fns'

interface UseDeviationVehicle {
  data: Ref<DeviationVehicle[]>
  remove: (uuid: string) => Promise<null>
  add: (body: Partial<DeviationVehicle>) => Promise<DeviationVehicle>
  update: (body: Partial<DeviationVehicle>) => Promise<DeviationVehicle>
  loading: ComputedRef<Loading>
  fetchAll: (params: FetchAllParams) => Promise<DeviationVehicle[]>
  period: Ref<Period>
  setDefaultPeriod: () => void
}

interface FetchAllParams {
  from: string
  to: string
}

interface Period {
  from: string
  to: string
}

function createDefaultPeriod() {
  return {
    from: format(sub(new Date(), { days: 5 }), 'yyyy-MM-dd'),
    to: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
  }
}

const period = ref<Period>(createDefaultPeriod())
const data = ref<DeviationVehicle[]>([])
const { state } = useLoading()

export function useDeviationVehicle(): UseDeviationVehicle {
  const { data: event } = useEvent()

  function setDefaultPeriod() {
    period.value = createDefaultPeriod()
  }

  async function fetchAll(fetchParams: FetchAllParams) {
    state.getAll = true
    return new Promise<DeviationVehicle[]>((resolve, reject) => {
      getDeviationVehicles(fetchParams)
        .then(({ data: deviationVehicles }) => {
          if (deviationVehicles !== undefined) {
            state.getAll = false
          }
          data.value = deviationVehicles || []
          resolve(deviationVehicles || [])
        })
        .catch(reject)
    })
  }

  async function remove(uuid: string) {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      deleteDeviationVehicle(uuid)
        .then(() => {
          if (event.value) {
            event.value.deviationVehicles =
              event.value.deviationVehicles.filter((item) => item.uuid !== uuid)
          }

          data.value = data.value.filter((item) => item.uuid !== uuid)
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  async function add(body: Partial<DeviationVehicle>) {
    state.create = true

    return new Promise<DeviationVehicle>((resolve, reject) => {
      createDeviationVehicle(body)
        .then(({ data: deviationVehicle }) => {
          if (event.value) {
            event.value.deviationVehicles.push(deviationVehicle)
          }
          data.value.push({
            ...deviationVehicle,
            eventName: body.eventName || null,
            eventId: body.eventId || null,
            eventIsOpen: body.eventIsOpen || null,
            eventDate: body.eventDate || null,
          })
          resolve(deviationVehicle)
        })
        .catch(reject)
        .finally(() => {
          state.create = false
        })
    })
  }

  async function update(body: Partial<DeviationVehicle>) {
    state.update = true

    const parsedBody = Object.assign({}, body)
    if (parsedBody.uuid) delete parsedBody.uuid

    return new Promise<DeviationVehicle>((resolve, reject) => {
      updateDeviationVehicle(parsedBody, body?.uuid || 'missing')
        .then(({ data: updateDeviationVehicle }) => {
          if (event.value) {
            event.value.deviationVehicles = event.value.deviationVehicles.map(
              (deviationVehicle) => {
                if (deviationVehicle.uuid === body?.uuid) {
                  return updateDeviationVehicle
                }

                return deviationVehicle
              }
            )
          }

          data.value = data.value.map((deviationVehicle) => {
            if (deviationVehicle.uuid === body?.uuid) {
              return {
                ...updateDeviationVehicle,
                eventName: body.eventName || null,
                eventId: body.eventId || null,
                eventIsOpen: body.eventIsOpen || null,
                eventDate: body.eventDate || null,
              }
            }

            return deviationVehicle
          })

          resolve(updateDeviationVehicle)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  return {
    data,
    fetchAll,
    remove,
    add,
    update,
    loading: computed(() => state),
    period,
    setDefaultPeriod,
  }
}
