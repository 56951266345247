
import { computed, defineComponent, ref } from 'vue'
import QTable from 'quasar/src/components/table/QTable.js';
import { format } from 'date-fns'
import { exportExcel } from '@/services/export-excel'
import { useDeviationVehicle } from '@/composable/useDeviationVehicle'
import { DeviationVehicle } from '@/types/deviation-vehicle'
import { useProfile } from '@/composable/useProfile'
import { useVehicleType } from '@/composable/useVehicleType'
import { useDeviationCancel } from '@/composable/useDeviationCancel'
import { DeviationCancel } from '@/types/deviation-cancel'
import { RouteParamsRaw, useRouter } from 'vue-router'
import DeviationVehicleWithCanceledFilterModal from './DeviationVehicleWithCanceledFilterModal.vue'

export default defineComponent({
  name: 'DeviatingVehicleWithCanceledTable',

  components: {
    DeviationVehicleWithCanceledFilterModal,
  },

  setup() {
    const router = useRouter()
    const {
      data: vehicleDeviations,
      loading: loadingStateDeviationVehicle,
      // fetchAll: fetchAllDeviationVehicle,
      setDefaultPeriod: setDefaultPeriodDeviationVehicle,
      // period,
    } = useDeviationVehicle()
    const {
      fetchAll: fetchAllVehicleTypes,
      loading: loadingVehiceType,
      data: vehicleTypes,
    } = useVehicleType()
    const {
      data: deviationCancels,
      loading: loadingStateDeviationCancel,
      // fetchAll: fetchAllDeviationCancel,
      setDefaultPeriod: setDefaultPeriodDeviationCancel,
    } = useDeviationCancel()
    const { projectDependentInputFields } = useProfile()

    setDefaultPeriodDeviationVehicle()
    setDefaultPeriodDeviationCancel()
    const exporting = ref(false)

    fetchAllVehicleTypes()
    // fetchAllDeviationVehicle(period.value)
    // fetchAllDeviationCancel(period.value)

    function getVehicleTypeName(vehicleTypeUuid: string) {
      return (
        vehicleTypes.value.find(
          (vehicleType) => vehicleType.uuid === vehicleTypeUuid
        )?.name || '-'
      )
    }

    const columns = computed<QTable['columns']>(() => {
      let defaultColumns: QTable['columns'] = [
        {
          align: 'left',
          name: 'advertised',
          required: true,
          label: 'Tåguppdrag',
          field: 'advertised',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'departureDate',
          required: true,
          label: 'Avgångsdatum',
          field: 'departureDate',
          format: (v: string) => v && format(new Date(v), 'yyyy-MM-dd'),
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'from',
          required: true,
          label: 'Från plats',
          field: 'from',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'to',
          required: true,
          label: 'Till plats',
          field: 'to',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'departure',
          format: (v: string) => v && format(new Date(v), 'HH:mm'),
          required: true,
          label: 'Avg.',
          field: 'departure',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'arrival',
          format: (v: string) => v && format(new Date(v), 'HH:mm'),
          required: true,
          label: 'Ank.',
          field: 'arrival',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
      ]
      if (projectDependentInputFields.value.vehicleChange) {
        defaultColumns.push({
          align: 'left',
          name: 'vehicleChange',
          required: true,
          label: 'Förändring antal fordon',
          field: 'vehicleChange',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        })
      }

      if (projectDependentInputFields.value.vehicleType) {
        defaultColumns = [
          ...defaultColumns,
          {
            align: 'left',
            name: 'plannedVehicleType1',
            required: true,
            label: 'Ord. M1',
            field: 'plannedVehicleType1',
            format: getVehicleTypeName,
            sortable: true,
            style: 'width: 0px; min-width: 0px; max-width: 0px',
          },
          {
            align: 'left',
            name: 'plannedVehicleType2',
            required: true,
            label: 'Ord. M2',
            format: getVehicleTypeName,
            field: 'plannedVehicleType2',
            sortable: true,
            style: 'width: 0px; min-width: 0px; max-width: 0px',
          },
          {
            align: 'left',
            name: 'plannedVehicleType3',
            required: true,
            label: 'Ord. M3',
            format: getVehicleTypeName,
            field: 'plannedVehicleType3',
            sortable: true,
            style: 'width: 0px; min-width: 0px; max-width: 0px',
          },
        ]
      }

      const columns: QTable['columns'] = [
        ...defaultColumns,
        {
          align: 'left',
          name: 'description',
          required: true,
          label: 'Kommentar',
          field: 'description',
          sortable: true,
        },

        {
          align: 'left',
          name: '_from',
          required: true,
          label: 'Inst. från plats',
          field: '_from',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: '_to',
          required: true,
          label: 'Inst. till plats',
          field: '_to',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },

        {
          align: 'left',
          name: '_eventId',
          required: true,
          label: 'Inst. tåg händelse',
          field: '_eventId',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
      ]

      return columns
    })

    const loading = computed(() => {
      return (
        loadingStateDeviationVehicle.value.getAll ||
        loadingVehiceType.value.getAll ||
        loadingStateDeviationCancel.value.getAll
      )
    })

    function hasDeviationCancel(
      deviationVehicle: DeviationVehicle,
      deviationCancel: DeviationCancel
    ) {
      return (
        deviationVehicle.advertised === deviationCancel.advertised &&
        deviationVehicle.departureDate === deviationCancel.departureDate
      )
    }

    const deviatingVehicleWithCanceled = computed(() => {
      return vehicleDeviations.value
        .filter((deviationVehicle) => {
          return deviationCancels.value.some((deviationCancel) =>
            hasDeviationCancel(deviationVehicle, deviationCancel)
          )
        })
        .map((deviationVehicle) => {
          const foundDeviationCancel = deviationCancels.value.find(
            (deviationCancel) =>
              hasDeviationCancel(deviationVehicle, deviationCancel)
          )
          return {
            ...deviationVehicle,
            _eventId: foundDeviationCancel?.eventId,
            _eventUuid: foundDeviationCancel?.eventUuid,
            _from: foundDeviationCancel?.from,
            _to: foundDeviationCancel?.to,
          }
        })
    })

    async function onExport() {
      exporting.value = true

      const exportData = {
        name: 'Fordonsavvikelser med inställda tåg',
        sheets: [
          {
            name: 'Fordonsavvikelser med inställda tåg',
            data: deviatingVehicleWithCanceled.value.map((item) => {
              return columns.value?.reduce<{ [name: string]: unknown }>(
                (acc, col) => {
                  const value =
                    typeof col.field === 'function'
                      ? col.field(item)
                      : item[col.name as keyof DeviationVehicle]

                  if (col.format) {
                    acc[col.label] = col.format(value, null)
                  } else {
                    acc[col.label] = value
                  }

                  return acc
                },
                {}
              )
            }),
          },
        ],
      }

      exportExcel(exportData)
      exporting.value = false
    }

    function openSearchDeviationEventNewTab(params: RouteParamsRaw) {
      const route = router.resolve({
        name: 'event',
        params,
      })

      window.open(route.href, '_blank')
    }

    return {
      columns,
      loading,
      deviatingVehicleWithCanceled,
      exporting,
      onExport,
      filterText: ref(''),
      openSearchDeviationEventNewTab,
    }
  },
})
