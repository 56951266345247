import { Ref, ref, ComputedRef, computed } from 'vue'
import { VehicleType } from '@/types/vehicle-type'
import { getVehicleTypes } from '@/api/vehicle-type/getVehicleTypes'
import { deleteVehicleType } from '@/api/vehicle-type/deleteVehicleType'
import { createVehicleType } from '@/api/vehicle-type/createVehicleType'
import { updateVehicleType } from '@/api/vehicle-type/updateVehicleType'
import { useLoading, Loading } from '@/composable/useLoading'

interface UseVehicleType {
  data: Ref<VehicleType[]>
  fetchAll: () => Promise<VehicleType[]>
  remove: (uuid: string) => Promise<null>
  add: (body: VehicleType) => Promise<VehicleType>
  update: (body: VehicleType) => Promise<VehicleType>
  loading: ComputedRef<Loading>
}

const data = ref<VehicleType[]>([])

export function useVehicleType(initFetchAll?: boolean): UseVehicleType {
  const { state } = useLoading()

  async function fetchAll() {
    state.getAll = true
    return new Promise<VehicleType[]>((resolve, reject) => {
      getVehicleTypes()
        .then(({ data: vehicleType }) => {
          data.value = vehicleType
          resolve(vehicleType)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function remove(uuid: string) {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      deleteVehicleType(uuid)
        .then(() => {
          data.value = data.value.filter((item) => item.uuid !== uuid)
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  async function add(body: VehicleType) {
    state.create = true
    return new Promise<VehicleType>((resolve, reject) => {
      createVehicleType(body)
        .then(({ data: vehicleType }) => {
          data.value.push(vehicleType)
          resolve(vehicleType)
        })
        .catch(reject)
        .finally(() => {
          state.create = false
        })
    })
  }

  async function update(body: VehicleType) {
    state.update = true
    return new Promise<VehicleType>((resolve, reject) => {
      updateVehicleType(body)
        .then(({ data: vehicleType }) => {
          data.value = data.value.map((item) => {
            if (item.uuid === vehicleType.uuid) {
              return {
                ...item,
                ...vehicleType,
              }
            }

            return item
          })
          resolve(vehicleType)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  if (initFetchAll) {
    fetchAll()
  }

  return {
    data,
    fetchAll,
    remove,
    add,
    update,
    loading: computed(() => state),
  }
}
